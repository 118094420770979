import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import "./layout.css";
import Studio from "./pages/Studio";
import Collab from "./pages/Collab";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Media from "./pages/Media";
import Friends from "./pages/Friends";
import Nav from "./Nav";
import { PastProjects, UpcomingProjects } from "./pages/Events";
import {isMobile} from "react-device-detect";

function App() {


  const getIsMobile = () => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const mobile = isMobile || vw < vh;
    return mobile;
  }
  const [mobile, setMobile] = useState(getIsMobile());

  const getNavBarHeight = () => { return document.getElementById("mobile-navigation-menu")?.getBoundingClientRect().height; };
  window.onresize = function() {
    const mobile = getIsMobile();
    setMobile(mobile);
    const navBarHeight = mobile ? getNavBarHeight() : 0;
    document.getElementById("app-content").style.paddingTop = ""  + navBarHeight + "px";
    // document.getElementById("background").style.backgroundPositionY = ""  + (navBarHeight - (window.innerWidth / 7.)) + "px";
    // var homePageBackgroundElements = document.getElementsByClassName("homePageBackground");
    // if (homePageBackgroundElements.length) {
    //   if (window.innerWidth < (window.innerHeight * 4. / 6.)) {
    //     homePageBackgroundElements[0].style.backgroundPositionX = "" + ((window.innerWidth - (window.innerHeight * 4. / 6.)) * 0.75) + "px";
    //   } else {
    //     homePageBackgroundElements[0].style.backgroundPositionX = "0px";
    //   }
    // }
  }
  
  window.onload = window.onresize;
  useEffect(window.onresize);
  const [isOpen, setOpen] = useState(false)



  // const [content, setContent] = useState(<Media/>);

  // const handleClick = (buttonContent) => {
  //   setContent(buttonContent);
  // };

  return (
    <div className="App">
      <div className={mobile ? "app-content mobile" : "app-content"} id="app-content">
        {/* <span className="navigation-menu">
          <div className="header">
            <a onClick={() => handleClick(<Media/>)}>
              <img src="media/keysAndInvertedKeys.png" alt="keyz" />
            </a>
          </div>
          {navigationButtons.map((button, index) => 
            <button key={index} onClick={() => handleClick(button.value)}>{button.label}</button>
            )}
        </span> */}
        <Nav mobile={mobile} isOpen={isOpen} setOpen={setOpen}/>
        <span className={mobile ? "main-section mobile" : "main-section desktop"}>
          {mobile ? <></> : <><h1>Sun Chang</h1><h2>Pianist, Harpsichordist</h2></>}
          <div>
            <BrowserRouter>
              <Routes>
                  <Route index element={<Media/>} />
                  <Route path="/about" element={<About/>} />
                  {/* <Route path="/studio" element={<Studio/>} /> */}
                  <Route path="/collab" element={<Collab/>} />
                  <Route path="/upcoming" element={<UpcomingProjects/>} />
                  <Route path="/contact" element={<Contact/>} />
                  <Route path="/friends" element={<Friends/>} />
                  <Route path="/past" element={<PastProjects/>} />
              </Routes>
            </BrowserRouter>
          </div>
        </span>
      </div>
    </div>
  );
}

export default App;
