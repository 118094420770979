// HOME PAGE / MEDIA
const youTubeURLs = [
    "https://www.youtube.com/embed/uwNVhd8Nr6g?start=190",
    "https://www.youtube.com/embed/Z58gyFV-yIk",
    "https://www.youtube.com/embed/U8C8WsuF_kA",
    "https://www.youtube.com/embed/DHlecaukpdU",
];
const youTubes = youTubeURLs.map(url => <iframe className="youtube" src={url} title="Sun Chang Plays" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>);
const soundCloud = [<iframe width="100%" height="150" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/232027873&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>];
const mp3s = [
<audio controls="controls" id="brahms_player">
    <source src="media/brahms2.mp3" type="audio/mpeg" />
    Your browser does not support the audio element.
</audio>
];

const streams = youTubes.concat(soundCloud).concat(mp3s);

export default () => streams.map(stream => <div className="stream">{stream}</div>);
