// import TextField from "@mui/material/TextField";

// export default () => <>
//   <div className="contactForm">
//     <form action="https://api.web3forms.com/submit" method="POST">
//       <input type="hidden" name="access_key" value="55d1bb61-8d66-4f75-8110-c7eeaa471dbb" />
//       <div>
//       <TextField fullWidth={true} margin="normal" label="Name" type="text" name="name" required />
//       </div>
//       <div>
//       <TextField fullWidth={true} margin="normal" label="E-mail" type="email" name="email" required />
//       </div>
//       <TextField multiline={true} fullWidth={true} margin="normal" label="Message" name="message" required />
//       <div class="h-captcha" data-captcha="true"></div>
//       <button className="contactButton" type="submit">Send e-mail</button>
//     </form>
//   </div>

//     <script src="https://web3forms.com/client/script.js" async defer></script>
//   </>;


const contactContent = <>
    <div>For contact, simply email me (string together these words and add some symbols to make it a normal email address):</div>
    <div id="email">sun chang piano AT gmail DOT com</div>
  </>;

  export default () => contactContent;