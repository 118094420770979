
  
  const friendsContent = <><h3>FRIENDS</h3>
  <div className="accentText">
  - people who helped me become me (teachers + wise friends (not exhaustive)) -
  </div>
  
  <ul className="friendList">
    <li>Dr. Peter Mack </li>
    <li>Alan Chow</li>
    <li>Carole Cerasi</li>
    <li>James Johnstone</li>
    <li>Laird Thornton</li>
    <li>Margaret Faultless</li>
    <li>Marc Durand</li>
    <li>Stephen Alltop</li>
  </ul>
  
  <div className="accentText">
  - collaborators (and they are also friends) -
  </div>
  
  <ul className="friendList">
    <li>Kiyoe Matsuura</li>
    <li>ProMusica Chamber Orchestra</li>
    <li>Vadim Gluzman</li>
    <li>Friction Quartet</li>
    <li>Ensemble Affect</li>
    <li>Ali Comerford, Michael Turkell, Victor Huls, Hannah Ji</li>
    <li>Judith Collective (Naomi, Margs, Gabby, Miri)</li>
  </ul>
  
  <div className="accentText">
  - mentors and/or harpsichord gurus -
  </div>
    
  <ul className="friendList">
    <li>Jason Moy</li>
    <li>Simon Neal</li>
    <li>Henry Lebedinsky</li>
    <li>Eric Budzynski</li>
  </ul>
  
  <div>
  I can't name every friend because now this page is looking like the afterword of a book. I did not write a book so I'm afraid to name and thank everyone in my life although that kind of seems like a fun and appropriate thing to do?
  </div>
  </>;

  export default () => friendsContent;
