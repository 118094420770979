import React from "react";
import Hamburger from "hamburger-react";


function Nav({mobile, isOpen, setOpen }) {

    const navigationButtons = [
        // { label: "About", value: <About/> },
        // { label: "Studio", value: <Studio/> },
        // { label: "Upcoming Projects", value: <UpcomingProjects/> },
        // { label: "Contact", value: <Contact/> },
        // { label: "Friends + Fun Stuff", value: <Friends/> },
        // { label: "Past Projects", value: <PastProjects/> },
        { shortLabel: "About", label: "About", href: "about" },
        // { shortLabel: "Studio", label: "Studio", href: "studio" },
        { shortLabel: "Collab", label: "Collab", href: "collab" },
        { shortLabel: "Upcoming", label: "Upcoming Projects", href: "upcoming" },
        { shortLabel: "Contact", label: "Contact", href: "contact" },
        { shortLabel: "Friends", label: "Friends + Fun Stuff", href: "friends" },
        { shortLabel: "Past", label: "Past Projects", href: "past" },
      ];
    // const navigationButtons = [
    //     { label: "Home", href: "" },
    //     { label: "Biography", href: "bio" },
    //     { label: "Events", href: "events" },
    //     { label: "Discography", href: "discog"},
    //     { label: "Media", href: "media" },
    //     { label: "Press Materials", href: "press" },
    //     { label: "WVU School of Music", externalLink: "https://www.music.wvu.edu/" },
    //     { label: "The Moanin' Frogs", externalLink: "https://www.themoaninfrogs.com/" },
    //     { label: "Publications", href: "publications" },
    //     { label: "Contact", href: "contact" },
    // ];
    
    const handleClick = (button) => {
        if (button.hasOwnProperty("href")) {
            let href = window.location.protocol + "//" + window.location.host + "/" + button.href;
            window.location.assign(href);
        }
        if (button.hasOwnProperty("externalLink")) {
            window.open(button.externalLink, "_blank");
        }
    };

    const getButtonClasses = (button) => {
        const visiting = window.location.pathname === "/" + button.href ? " visiting" : "";
        const mobileClass = mobile ? " mobile" : ""
        return "navigationButton" + mobileClass + visiting;
    }
 
    return mobile
        ? <div className="navigation-menu mobile" id="mobile-navigation-menu">
            { isOpen ? <div className="headerContent">{navigationButtons.map((button, index) => 
                <button className={getButtonClasses(button)} key={index} onClick={() => handleClick(button)}>{button.shortLabel}</button>)}</div>
                : <><div className="headerContent">Sun Chang <div className="headerSubcontent"> Piano, Harpsichord</div></div></>
            }
            <Hamburger toggled={isOpen} toggle={setOpen} color="#FFFFFF" onClick={window.onresize}/>
            </div>
        : <span className="navigation-menu desktop">
        <div className="header">
            <a onClick={() => handleClick({href:""})}>
                <img src="media/keysAndInvertedKeys.png" alt="keyz" />
            </a>
        </div>
        {navigationButtons.map((button, index) => 
        <button className={getButtonClasses(button)} key={index} onClick={() => handleClick(button)}>{button.label}</button>
        )}
        </span>
}

export default Nav;
    